import React from 'react'

import Layout from "../components/layout"
import Head from "../components/head"
import SEO from '../components/seo'

import phone from "../../static/img/phone.png"
import email from "../../static/img/email.png"
import facebook from "../../static/img/facebook.png"

const ContactPage = () => {
    return (
        <Layout>
            <SEO />
            <Head title="Contact"/>
            <h1>Contact</h1>
            <hr />
            <br />
            <div className="content-company-intro">
                <div className="contact-company-attr">
                    <div>
                        <a href="tel:+6097409389">
                            <img className="contact-phone" src={phone} alt="CallUs" />
                        </a>
                    </div>
                    <br />
                    <b>Call us: +609 7409389</b>
                </div>
                <div className="contact-company-attr">
                    <div>
                        <a href="mailto:info@mycentralsummit.com">
                            <img className="contact-email" src={email} alt="Email" />
                        </a>
                    </div>
                    <br />
                    <b>Email</b>
                </div>
                <div className="contact-company-attr">
                    <div>
                        <a href="https://www.facebook.com/mycentralsummit/" target="_blank">
                            <img className="contact-fb" src={facebook} alt="Facebook" />
                        </a>
                    </div>
                    <br />
                    <b>Facebook</b>
                </div>
            </div>
        </Layout>
    )
}

export default ContactPage
